import { CT } from "../../consts";
import {
  cardListTemplateLoader,
  cardTemplateLoader,
} from "../../loader/templates/cardsview";
import { cardSliderTemplateLoader } from "../../loader/templates/cardsslider";
import { bubbleTemplateLoader } from "../../loader/templates/bubble";
import { genericLoader } from "../../loader/templates/generic";

require("./../../sass/loader.scss");

const EventModalHTML = (id) => `<div
      class="event-modal--wrapper_rev-loader"
      id="${id}"
    >
      <div
        class="event-modal--container_rev-loader"
        id="rev-modal-event-loader"
      >
        <div class="rev-calendar-closer-container">
          <div
            class="event-modal--close-container"
          >
            <i class="material-icons" aria-hidden=true>
              close
            </i>
          </div>
        </div>

        <div
          class="events-list--row-wrapper_rev-loader rev-preloader-block"
        >
          <div class="events-list--date-container_rev-loader "></div>
          <div class="event-details--wrapper_rev-loader_rev-loader">
            <div class="events-list--image-container_rev-loader " ></div>
            <div class="events-list--block-container_rev-loader" ></div>
          </div>
        </div>
      </div>
    </div>`;

const templatesMapping = {
  [CT.card]: cardTemplateLoader,
  [CT.cardList]: cardListTemplateLoader,
  [CT.cardSlider]: cardSliderTemplateLoader,
  [CT.bubble]: bubbleTemplateLoader,
  // events list
  // weekly
  // monthly
  // detailed list
};
const CalendarPreloader = ({ widgetUUID }) => {
  const loaderModalId = `loader-${widgetUUID}--modal`;
  const rootNode = document.querySelector(`#loader--${widgetUUID}`);
  let initialDocumentBodyOverflow = document.body.style.overflow;

  const url = new URL(window.location.href);
  let singleItemSlug = null;
  let hasSingleEvent = false;

  const eventParam = url.searchParams.get("event");
  const calendarUUid = url.searchParams.get("calendar");
  if (eventParam && calendarUUid === widgetUUID) {
    const eventSlug = eventParam.split("----");

    if (eventSlug.length === 2) {
      let slug = eventSlug[0];
      singleItemSlug = slug;
      hasSingleEvent = slug && !document.querySelector(`#${loaderModalId}`);
    }
  }

  const widgetTemplate = document.getElementById(widgetUUID)?.dataset.wt || "";

  rootNode.innerHTML = templatesMapping[widgetTemplate] || genericLoader;

  const listener = (event) => {
    const hasEventModal = document.querySelector(`#${loaderModalId}`);

    if (event.data?.type === "rev-item-loaded") {
      // if it has event modal, remove it
      if (hasEventModal) {
        if (rootNode) {
          hasEventModal.parentNode.removeChild(hasEventModal);
          document.body.style.overflow = initialDocumentBodyOverflow;
        }
      }

      //
      if (event.data?.widgetUUID === widgetUUID) {
        window.removeEventListener("message", listener);
        rootNode.parentNode.removeChild(rootNode);
      }
    }

    // widget loaded
    if (
      event.data?.type === "rev-widget-loaded" &&
      event.data?.widgetUUID === widgetUUID &&
      !hasEventModal
    ) {
      // console.log("turning the loader off", event.data?.widgetUUID, widgetUUID);
      /*if (
        !url.searchParams.has("calendar") ||
        url.searchParams.get("calendar") !== event.data.widgetUUID
      ) {*/
      // console.log("turning the loader modal off", rootNode);
      window.removeEventListener("message", listener);
      rootNode.parentNode.removeChild(rootNode);
      // }
    }
  };

  window.addEventListener("message", listener);

  if (hasSingleEvent) {
    const modal = EventModalHTML(loaderModalId);
    document.body.insertAdjacentHTML("beforeend", modal);
    document.body.style.overflow = "hidden";

    // console.log("turning the loader modal on", widgetUUID);
    setTimeout(() => {
      document
        .querySelector(`#${loaderModalId}`)
        ?.classList.add("show_rev-loader");
      document
        .querySelector(`#${loaderModalId} .event-modal--container_rev-loader`)
        ?.classList.add("show_rev-loader");
    }, 150);

    document
      .querySelector(`#${loaderModalId} .event-modal--close-container`)
      .addEventListener("click", () => {
        // remove calendar and event params from the url and push the new url
        /*window.postMessage(
          {
            type: "rev-item-loaded",
            widgetUUID,
            widgetSlug: url.searchParams.get("calendar"),
          },
          "*"
        );

        */
        window.postMessage({ type: "rev-item-load-cancel", widgetUUID }, "*");
        if (rootNode) {
          const hasEventModal = document.querySelector(`#${loaderModalId}`);

          hasEventModal.parentNode.removeChild(hasEventModal);
          document.body.style.overflow = initialDocumentBodyOverflow;
        }
        rootNode.parentNode.removeChild(rootNode);

        // remove query params from the url
        url.searchParams.delete("calendar");
        url.searchParams.delete("event");
        window.history.pushState({}, "", url);
      });
  }
};

export default CalendarPreloader;
