import { dceDebug } from "./debug";
import appConfig from "../appConfig";

/**
 *
 * @param callback
 */
export const onLocationChange = (callback) => {
  let oldHref = document.location.href;
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (oldHref != document.location.href) {
        const currentUrl = new URL(document.location.href);
        const oldUrl = new URL(oldHref);

        // run only if location has changed, ignore hash and query params
        if (currentUrl.pathname !== oldUrl.pathname) {
          oldHref = document.location.href;
          dceDebug("location changed", oldHref);
          callback();
        }
      }
    });
  });

  const config = {
    childList: true,
    subtree: true,
  };

  observer.observe(document, config);
};

/**
 *
 * @param callback
 */
export const onDocumentLoad = (kind, callback) => {
  dceDebug("ready()", window.__DCE[kind]?.isWidgetLoaded);

  if (document.readyState != "loading") {
    dceDebug(
      "ready() document.readyState",
      document.readyState,
      window.__DCE[kind]?.isWidgetLoaded
    );

    if (!window.__DCE[kind]) {
      window.__DCE[kind] = {};
    }

    if (!window.__DCE[kind]?.isWidgetLoaded) {
      window.__DCE[kind].isWidgetLoaded = true;
      callback();
    }
  }

  // modern browsers
  else if (document.addEventListener) {
    document.addEventListener("DOMContentLoaded", function () {
      dceDebug(
        "ready() document.addEventListener DOMContentLoaded",
        window.__DCE[kind].isWidgetLoaded
      );
      if (!window.__DCE[kind].isWidgetLoaded) {
        window.__DCE[kind].isWidgetLoaded = true;
        callback();
      }
    });
  } else {
    // IE <= 8
    document.attachEvent("onreadystatechange", function () {
      dceDebug(
        "ready() onreadystatechange",
        document.readyState,
        window.__DCE[kind].isWidgetLoaded
      );
      if (
        document.readyState == "complete" &&
        !window.__DCE[kind].isWidgetLoaded
      ) {
        window.__DCE[kind].isWidgetLoaded = true;
        callback();
      }
    });
  }
};

/**
 *
 * @param src
 * @param attrs
 * @param callback
 */
export const attachScript = (src, attrs = null, callback = null) => {
  var script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.id = "dce-embeddable-script";

  if (attrs) {
    for (const key in attrs) {
      script.setAttribute(key, attrs[key]);
    }
  }

  if (callback) {
    script.onload = callback;
  }
  document.body.appendChild(script);
};

/**
 *
 * @returns {HTMLElement|null}
 */
export const getEmbeddableScriptDom = () => {
  const supportedScriptIds = [
    "dce-embeddable-script-js", // WP plugin
    "rev-embeddable-script-js",
    "dce-embeddable-script",
    "rev-embeddable-script",
    "dc-wix-embeddable-script",
    "rev-wix-embeddable-script",
    // fallback
    "sync-ccb-script",
  ];

  for (const scriptId of supportedScriptIds) {
    let scriptTag = document.getElementById(scriptId);

    if (scriptTag) {
      return scriptTag;
    }
  }

  return null;
};

/**
 * @returns {*|string}
 */
const getEmbeddableOrigin = () => {
  const dom = getEmbeddableScriptDom();

  if (dom && dom.dataset.widgetsEndpoint) {
    return dom.dataset.widgetsEndpoint;
  }
  return dom ? dom.src : window.location.origin;
};

/**
 * Returns backend address based on the source of the embeded script.
 * It also handles fallback for v0 where `sync-ccb-script` tag was originally used.
 * @returns {string}
 */
export const getEmbeddableSource = () => {
  const origin = getEmbeddableOrigin();
  dceDebug(origin);
  dceDebug(window);

  /**
   * allowed origins for embeddable script in production:
   * my.display.church,
   * dev.display.church,
   * Additional origins for production but testing purposes:
   * localhost,
   * dev.dce
   */
  // const fakeBaseURLDOM = document.createElement("a");
  // fakeBaseURLDOM.setAttribute("href", appConfig.homepage);

  const fakeBaseURLDOM = new URL(appConfig.homepage);

  const allowedOrigins = [
    // `my.${fakeBaseURLDOM.hostname}`,
    `cdn.my.${fakeBaseURLDOM.hostname}`,
    // `dev.${fakeBaseURLDOM.hostname}`,
    `cdn.dev.${fakeBaseURLDOM.hostname}`,
    `demo.${fakeBaseURLDOM.hostname}`,
    "localhost",
    "dev.dce",
  ];

  // Parse the querystring into arguments and parameters
  const fakeDOM = document.createElement("a");
  fakeDOM.setAttribute("href", origin);

  // if the origin is not allowed, fallback to my.display.church
  const hostname = allowedOrigins.includes(fakeDOM.hostname)
    ? allowedOrigins.find((allowedOrigin) => allowedOrigin === fakeDOM.hostname)
    : `cdn.my.${fakeBaseURLDOM.hostname}`;

  dceDebug("fakeBaseURLDOM hostname", fakeBaseURLDOM.hostname);
  dceDebug("appconfig", appConfig);
  dceDebug("origin", fakeDOM.hostname);
  dceDebug("allowed origins", allowedOrigins);

  dceDebug(
    allowedOrigins.includes(fakeDOM.hostname),
    fakeDOM.protocol,
    fakeDOM.hostname,
    fakeDOM.port,
    "used hostname",
    hostname
  );

  // DEV ONLY
  // return "https://8127-2a0d-3344-330d-df10-d14c-a0ba-a35a-7cd.ngrok-free.app";

  const backendAddress =
    fakeDOM.protocol +
    "//" +
    hostname +
    (fakeDOM.port.length ? ":" + fakeDOM.port : "");

  return backendAddress;
};

/**
 * It tries to locate all iframes on wix page. For every iframe it will run a callback
 * with iframe DOM object and index i parameters. It can be used to post a message to a content
 * window.
 * @param callback
 */
export const postMessageToWixIframes = (callback) => {
  let attempts = 50;
  let interval = setInterval(() => {
    const iframes = document.querySelectorAll('iframe[name="htmlComp-iframe"]');

    if (!iframes.length) {
      attempts--;

      if (attempts == 0) {
        clearInterval(interval);
      }

      return;
    }

    clearInterval(interval);

    for (let i = 0; i < iframes.length; i++) {
      if (!iframes[i].closest("wix-iframe")) {
        continue;
      }
      callback(iframes[i], i);
    }
  }, 500);
};

/**
 *
 * @param dom
 * @returns {boolean}
 */
export const filterInitializedWidgets = (dom) => {
  // consider if dom has first div with class "loader"
  const loaderDom = document.querySelector(`[id='${dom.id}'] > div.loader`);
  if (loaderDom) {
    return true;
  }

  if (!dom.children.length) {
    return true;
  }

  if (dom.children.length) {
    const firstChild = dom.children[0];
    return !Array.from(firstChild.classList).some((className) =>
      className.startsWith("dce-")
    );
  }
};
