/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/04/2022.
 */

// console.log("Hello Jason!");

import { dceDebug } from "./helpers/debug";
import {
  onDocumentLoad,
  getEmbeddableSource,
  getEmbeddableScriptDom,
  postMessageToWixIframes,
  onLocationChange,
  attachScript,
  filterInitializedWidgets,
} from "./helpers/launcher";
import { WT } from "./apps/admin/consts/widget";
import appConfig from "./appConfig";
import CalendarPreloader from "./apps/calendar/preloader";
const KIND = "loader";
const backendAddress = getEmbeddableSource();
const scriptTag = getEmbeddableScriptDom();

export const postCalendarLoader = (widgetName) => {
  dceDebug("aapostCalendarLoader: ", widgetName);
  const kind = `${widgetName}Loader`;
  const widgetSelector = ".dce-calendar, .ccb-events";
  window.__DCE[kind] = {
    init: false,
    initializedWidgets: [],
  };
  const widgetObjects = document.querySelectorAll(widgetSelector);

  // console.log("widgetObjects", widgetObjects);
  if (!widgetObjects.length) {
    console.warn(
      `${appConfig.displayName}: No ${widgetName} widget objects present on this page`
    );
    return;
  }

  for (let i = 0; i < widgetObjects.length; i++) {
    const widgetAttributes = widgetObjects[i].attributes;
    const isIframe =
      widgetAttributes.iframe && widgetAttributes.iframe.value === "true";

    if (isIframe) {
      console.warn(
        "skipping post calendar loader due to iframe or wix",
        widgetObjects[i].id
      );
      continue;
    }
    const slug = isIframe ? widgetAttributes.slug.value : null;

    let singleItemSlug = widgetAttributes.singleitem
      ? widgetAttributes.singleitem.value
      : null;

    if (widgetAttributes.singleeventslug) {
      // fallback to v2
      singleItemSlug = widgetAttributes.singleeventslug.value;
    }

    // console.log("runWidget", kind, widgetObjects[i].id, isIframe);
    try {
      const domID = document.getElementById(widgetObjects[i].id);

      if (!domID) {
        console.warn("Widget not found, ID not available", widgetObjects[i].id);
        continue;
      }

      if (domID.children.length) {
        console.warn("Widget already loaded", widgetObjects[i].id);
        continue;
      }

      const loaderContainerId = `loader--${domID.id}`;

      if (document.getElementById(loaderContainerId)) {
        console.warn(
          "Widget has loader initialized already, skipping",
          widgetObjects[i].id
        );
        continue;
      }

      const loaderDom = document.createElement("div");
      loaderDom.className = "rev-preloader--container";
      loaderDom.id = loaderContainerId;
      loaderDom.dataset.wt = domID.dataset.wt;

      domID.parentNode.insertBefore(loaderDom, domID.nextSibling);
      CalendarPreloader({
        loaderContainerId,
        slug,
        singleItemSlug,
        widgetUUID: domID.id,
      });

      window.__DCE[kind].init = false;
    } catch (e) {
      console.error(
        "Loading preloader widget" + widgetObjects[i].id + " failed"
      );
      console.error(e);
    }
  }
};

const loadWidget = (widgetName) => {
  const widgetFileName = `${widgetName}${window.__DCE__DEV__ ? "" : ".min"}.js`;
  const widgetUri = `${backendAddress}/widgets/${widgetFileName}`;

  dceDebug("loading widget: ", widgetName, widgetUri);

  if (widgetName === WT.calendar) {
    postCalendarLoader(widgetName);
  }

  attachScript(widgetUri);
};

/**
 *
 * @param kind
 * @param widgetId
 */
const scrollToWidget = (kind, widgetId) => {
  // scroll to this div if query param contains its uuid
  if (window.location.search.includes(`${kind}=${widgetId}`)) {
    const element = document.getElementById(widgetId);
    if (element) {
      element.scrollIntoView();
    }
  }
};
const loadedWidgets = [];

const initializeWidgets = () => {
  dceDebug("initializeWidgets");

  // .ccb-events is for backward compatibility, used by all calendars embedded before v3.
  const embeddableDoms = Array.from(
    document.querySelectorAll(
      ".dce-calendar, .dce-groups, .ccb-events, .dce-signup"
    )
  )
    // do not initialize widgets that are initialized
    .filter(filterInitializedWidgets);

  if (!embeddableDoms.length && !scriptTag && !scriptTag?.getAttribute("wix")) {
    setTimeout(initializeWidgets, 500);
    return;
  }

  if (
    !scriptTag ||
    !scriptTag.getAttribute("wix") ||
    (scriptTag.getAttribute("wix") === "true" &&
      scriptTag.getAttribute("wixbuilder") === "true")
  ) {
    for (const dom of embeddableDoms) {
      dom.classList.forEach((value, key) => {
        // check for backward compatibility of ccb-events classname
        if (value.toLowerCase() === "ccb-events") {
          if (!loadedWidgets.includes(WT.calendar)) {
            loadedWidgets.push(WT.calendar);
            loadWidget(WT.calendar);
            scrollToWidget(WT.calendar, dom.id);
          }
          return;
        }

        const widget = value.replace("dce-", "");
        dceDebug("widget", widget);
        if (!loadedWidgets.includes(widget)) {
          loadedWidgets.push(widget);
          loadWidget(widget);
          scrollToWidget(widget, dom.id);
        }
      });
    }
  } else {
    const sendAdvertisementToWix = () => {
      postMessageToWixIframes((iframe, i) => {
        iframe.contentWindow.postMessage({ advertise: true }, "*");
      });
    };

    let sentAdvertisement = false;
    const interval = setInterval(() => {
      if (loadedWidgets.length) {
        clearInterval(interval);

        // send andvertisement in case all widgets are loaded and location has changed
        if (!sentAdvertisement) {
          sendAdvertisementToWix();
        }
        return;
      }
      sentAdvertisement = true;
      sendAdvertisementToWix();
    }, 300);
  }
};

// const reinitializeWidgets = (type, uuid) => {
//   dceDebug("reinitializeWidgets", type, uuid);
//   runWidgetInitialization(type, `div[id='${uuid}']`);
// };

(() => {
  window.__DCE = window.__DCE || {};

  const ID = new Date() * 1;

  dceDebug("Loader script ID", ID, window.DCELOADERID);

  if (window.DCELOADERID) {
    dceDebug("Loader script already initialized, skipping");
    return;
  } else {
    window.DCELOADERID = ID;
    window.__DCE[KIND] = {
      isWidgetLoaded: false,
    };
  }

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    window.__DCE__DEV__ = true;
  } else {
    window.__DCE__DEV__ = false;
  }

  // Local variable that stores the widgets that were enqueued to be loaded. We can't rely on the window.__DCE object
  // because it's not updated immediately after the message event. It happens asynchronously in the other script.
  const enqueuedWidgetsIds = [];

  // Initialize widgets only on document load
  onDocumentLoad("loader", initializeWidgets);

  // this is for websites that are SPA - location change does not make the whole website reload but just a new
  // entry in the history is created. This is the case for Wix websites but also for some other websites as well.
  onLocationChange(() => {
    // Clear the enqueued widgets
    enqueuedWidgetsIds.length = 0;
    Object.keys(window.__DCE).forEach((key) => {
      if (key !== KIND) {
        window.__DCE[key].initializedWidgets.length = 0;
      }
    });

    // Reinitialize widgets
    initializeWidgets();
  });

  const wixBroadcastsListener = (event) => {
    // builder mode
    if (
      event.data.type &&
      !loadedWidgets.includes(event.data.type) &&
      event.data.builder
    ) {
      // console.log("builder mode message", event);
      loadedWidgets.push(event.data.type);
      // console.log("builder wix load widget");
      loadWidget(event.data.type);

      return;
    }

    // production mode

    // event not related
    if (!event.data.type || !WT[event.data.type]) {
      return;
    }

    /**
     * In production, Wix embeds widgets in the IFRAME.  This script listens to the message event and replaces the IFRAME with a DIV.
     * First, it finds the right IFRAME by comparing the source window object with the event.source.
     */
    const iframes = document.getElementsByTagName("IFRAME");
    let target = null;

    for (let i = 0, iframe, win; i < iframes.length; i++) {
      iframe = iframes[i];

      // Cross-browser way to get iframe's window object
      win = iframe.contentWindow || iframe.contentDocument.defaultView;

      if (win === event.source) {
        target = iframe;
        break;
      }
    }

    if (!target) {
      // This is a rare case when the iframe is not found, but the message is still received.
      return;
    }

    // This is to record what widgets are enqueued to be loaded. This is to prevent loading the same widget twice.
    // Depending on Wix, it may reload the embedded HTML content multiple times, hence this check.
    if (!enqueuedWidgetsIds.includes(event.data.widgetId)) {
      enqueuedWidgetsIds.push(event.data.widgetId);
    } else {
      return;
    }

    // turning iframe into div so it's visible by the widget's script as DIV with the proper ID
    const wixFrame = target.closest("wix-iframe");
    wixFrame.innerHTML = `<div class="dce-${event.data.type}" id="${event.data.widgetId}" data-wt="${event.data.template}"/>`;
    // this is to ensure that the content of our widget isn't cut off
    wixFrame.closest("div").style.height = "auto";

    // turns the div into a widget loader and register listener for the widget initialization.
    // This improves UX as it shows a loader until the widget is fully loaded.
    if (event.data.type === WT.calendar) {
      postCalendarLoader(event.data.type);
    }

    // This payload will tell the widget's script that this widget is ready to be loaded.
    const messagePayload = {
      type: "dce-embeddable",
      widgetType: event.data.type,
      widgetUUID: event.data.widgetId,
    };

    // TODO: I don't know why we need window.parent. window.__DCE does not contain all the information from the widget's script.
    // So if we have in the loader script window.__DCE[loader] set and in index-calendar.js we set window.__DCE[calendar] initialized
    // then in here the window.__DCE[calendar] will remain undefined. But when using window.parent - all works ok.
    // Explain why it's happening like so and replace this comment.
    const parentWindowData = { ...window.parent.__DCE };

    if (
      parentWindowData[event.data.type] === undefined &&
      !loadedWidgets.includes(event.data.type)
    ) {
      // this case is when the widget's script wasn't loaded nor initialized yet
      loadedWidgets.push(event.data.type);
      const widgetFileName = `${event.data.type}${
        window.__DCE__DEV__ ? "" : ".min"
      }.js`;
      const widgetUri = `${backendAddress}/widgets/${widgetFileName}`;

      // attaches the widget script. When it's laoded it will post a message to the widget's script.
      attachScript(widgetUri, { wix: true }, () => {
        // console.log("on script load callback");
        window.postMessage(messagePayload, "*");
      });
    } else {
      // This case is when the widget's script is loaded. It may not be initialized yet, though.

      // race condition if posting this message before the script is actually loaded, hence interval
      const interval = setInterval(() => {
        const parentWindowData = { ...window.parent.__DCE };

        // widget not initialized yet, try again in 100ms
        if (!parentWindowData[event.data.type]?.isWidgetLoaded) {
          return;
        }

        // widget is initialized, means it's ready to listen for this type of message
        clearInterval(interval);

        window.postMessage(messagePayload, "*");
      }, 100);
    }
  };

  window.addEventListener("message", wixBroadcastsListener);
})();
